/**
 * Utilities specific to MOCPA
 */

/**
 * Dependencies
 */
import isExternalURL from '../../vendor/imarc/inkwell/resources/scripts/modules/linkObserver';

window.addEventListener('content-populated', () => {
	const anchors = document.querySelectorAll('a');

	if (anchors.length) {
		anchors.forEach(anchor => {
			if (isExternalURL(anchor)) {
				anchor.classList.add('js-external');
			}
		});
	}
});
